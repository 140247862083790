import '@hotwired/turbo'
import './styles/main.scss'
import './initializers/chunks'

document.addEventListener('DOMContentLoaded', () => {
  require('./initializers/stimulus').default()
  require('./initializers/externals').default()
  require('./initializers/aos').default()
  require('./initializers/discovery_call_push').default()
  require('@tfx/static/initializers/referrers').default()
})
